import React from 'react';

import './SeeDailyProgressSection.css';
import dashboard from '../../images/dashboard.svg';
import { Button } from 'antd';

const SeeDailyProgressSection = () => (
  <div className="SeeDailyProgressSection">
    <div className="daily-progress-container">
      <div style={{ alignItems: top }}>
        <div className="main-header-white" style={{ width: 394, marginBottom: 30, fontSmooth: true }}>
        Apply to hundreds of jobs per week.        </div>
        <div className="main-subtitle-white" style={{ width: 393 }}>
          With our comprehensive application dashboard, you can see what roles your recruiter has
          applied you for in realtime.{' '}
        </div>
        <a href='#howitworks'>  
        <Button className="green-cta"> See how it works</Button>
        </a>
      </div>
      <div>
        <img src={dashboard} height={340} width={587} alt="Jobseeker Dashboard" />
      </div>
    </div>
  </div>
);

SeeDailyProgressSection.propTypes = {};

SeeDailyProgressSection.defaultProps = {};

export default SeeDailyProgressSection;
