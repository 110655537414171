import React from 'react';

import './LandingPage.css';
import LandingPageHeader from '../LandingPageHeader/LandingPageHeader';
//import { Image } from 'antd';
import PersonalRecuiterSection from '../PersonalRecuiterSection/PersonalRecuiterSection';
import PricingPage from '../PricingPage/PricingPage';
import landingpageimage from '../../images/header-image@2x.svg';
import FrustratedJobseekerSection from '../FrustratedJobseekerSection/FrustratedJobseekerSection';
import SeeDailyProgressSection from '../SeeDailyProgressSection/SeeDailyProgressSection';
import HowItWorksSection from '../HowItWorksSection/HowItWorksSection';
import { Button } from 'antd';

const LandingPage = () => (
  <div className="LandingPage">
    <LandingPageHeader />

    <div className="hero-display-web">
      <div className="hero-container">
        <h1>We Apply For You.</h1>
        <div className="subtitle">
          ApplyStorm accelerates your job search journey. Let us handle the hard part, while you
          continue to work on you.
        </div>
        <a href='#pricing' >
       
        <Button className='blue-cta'>
          Get Your First Month FREE *
        </Button>
        </a>
        {/* <div className="cta-deal">Signup Today. Your First 20 Job Applications Are On Us</div> */}
        {/* <div className="cta-buttons">
          <Button type="primary">Sign Up</Button>
          <Button type="link">Learn more</Button>
        </div> */}
      </div>

      <div>
        <img src={landingpageimage} alt="ApplyStorm" height={286} width={479} />
      </div>
    </div>
    <div className="seperator"></div>
    <FrustratedJobseekerSection />
    <div className="seperator"></div>
    <SeeDailyProgressSection />
    <div className="seperator"></div>
    <PersonalRecuiterSection />
    <div className="seperator"></div>
    <PricingPage />
    <div className="seperator"></div>
    <HowItWorksSection />
    <div className="seperator"></div>
  </div>
);

LandingPage.propTypes = {};

LandingPage.defaultProps = {};

export default LandingPage;
